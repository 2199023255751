<template>
  <div class="paragraph paragraph--text">
    <div
      v-if="paragraphText.attributes"
      class="field--text"
      v-html=" paragraphText.attributes.field_text.value"
    />
  </div>
</template>

<script>
import { getParagraph } from '../plugins/drupalApi'

export default {
  name: 'ParagraphText',
  props: {
    uuid: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      paragraphText: []
    }
  },
  async created () {
    this.paragraphText = await getParagraph('text', this.uuid)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/paragraphs/paragraph-text"
</style>
