<template>
  <header id="page-header">
    <div :class="headerClasses">
      <div class="logo">
        <a href="/">
          <img
            v-if="isHome"
            class="header--logoHome"
            src="@/assets/layout/logo.png"
            alt="Logo One of A Kind (OAK) Collection"
          >
          <img
            class="header--logoAll"
            src="@/assets/layout/logo_allPages.png"
            alt="Logo One of A Kind (OAK)  deux test Collection"
          >
        </a>
      </div>
      <div class="menu" @click="openModal">
        <img
          src="~@/assets/layout/burger_menu.png"
          alt="OAK menu"
        >
      </div>
      <div id="main-menu-links">
        <ul class="menu-links">
          <li><a href="/">Home</a></li>
          <li><a href="/exhibition-map">Exhibition map</a></li>
          <li><a href="/article/exhibition-of-exraordinary-watches?uuid=c5045eb9-4be4-41c4-9b49-b0df81fab6d2">Exclusive content</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import Global from '../plugins/global'

export default {
  name: 'Header',
  props: {
    headerClasses: {
      type: String,
      default: '',
      required: true
    },
    isHome: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    openModal: function () {
      Global.openModal('#main-menu-links')
    }
  },
  mounted () {
    Global.initModal('#main-menu-links')
    Global.manageHomeHeader()
  }
}
</script>
