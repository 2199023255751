<template>
  <div class="showcase--teaser">
    <div class="showcase--header">
      <div class="col-left">
        <img :src="mainImage">
      </div>
      <div class="col-right">
        <div class="showcase-name">
          Showcase {{ showcase.name }}
        </div>
        <ul
          v-if="showcase.categories.length"
          class="showcase-categories"
        >
          <li
            v-for="category in showcase.categories"
            :key="category.id"
          >
            <a :href="category.url">{{ category.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getMediaImage } from '../plugins/drupalApi'

export default {
  name: 'ShowcaseTeaser',
  props: {
    showcase: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      mainImage: null,
      loaded: false
    }
  },
  async created () {
    if (this.showcase.media != null) {
      var image = await getMediaImage(this.showcase.media)
      this.mainImage = image.included[0].attributes.image_style_uri.oak_category_other
    }
  },
  updated () {
    if (this.mainImage && this.loaded === false) {
      this.loaded = true
      this.$parent.loadedCats += 1
    }
  }
}
</script>
