<template>
  <div class="article--teaser">
    <div class="article-content">
      <div class="image">
        <img :src="mainImage">
      </div>
      <div class="article--content-inner">
        <div class="tags">
          <div
            v-for="tag in article.included"
            :key="tag.id"
            class="tag"
          >
            {{ tag.attributes.name }}
          </div>
        </div>
        <div class="title">{{ article.attributes.title }}</div>
        <div class="field_teaser_text">{{ article.attributes.field_teaser_text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMediaImage } from '../plugins/drupalApi'

export default {
  name: 'ArticleTeaser',
  props: {
    article: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      mainImage: null
    }
  },
  async created () {
    if (this.article.relationships.field_teaser_media.data !== null) {
      var image = await getMediaImage(this.article.relationships.field_teaser_media.data.id)
      this.mainImage = image.included[0].attributes.image_style_uri.oak_article_large
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/node/article-teaser";
</style>
