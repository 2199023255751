<template>
  <div class="paragraph paragraph--gallery">
    <div
      v-if="imagesUrl.length"
      class="field--gallery slick"
    >
      <div
        v-for="url in imagesUrl"
        :key="url"
        class="gallery-slide"
      >
        <img :src="url">
      </div>
    </div>
  </div>
</template>

<script>
import { getParagraph, getGalleryImages, getMediaImage } from '../plugins/drupalApi'
import '../libraries/slick/slick.min.js'
import Global from '../plugins/global'

export default {
  name: 'ParagraphGallery',
  props: {
    uuid: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      paragraphGallery: [],
      imagesUrl: [],
      slickSettings: {
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerPadding: '0px'
      }
    }
  },
  async created () {
    var imagesArr = []
    this.paragraphGallery = await getParagraph('image', this.uuid)
    this.paragraphGallery.images = await getGalleryImages(this.paragraphGallery.relationships.field_media.data.id)
    for (var i = 0; i < this.paragraphGallery.images.included.length; i++) {
      var image = await getMediaImage(this.paragraphGallery.images.included[i].id)
      imagesArr.push(image.included[0].attributes.image_style_uri.oak_article_large)
    }
    this.imagesUrl = imagesArr
  },
  updated () {
    Global.slickCarousel('.slick:not(.slick-initialized)', this.slickSettings)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/paragraphs/paragraph-gallery";
@import "./src/libraries/slick/slick.scss";
@import "./src/libraries/slick/slick-theme.scss";
</style>
