<template>
  <div
    class="product--teaser"
    :id="product.id"
    @click="openModalForProduct"
  >
    <div
      v-if="isBasic"
      class="product--content no-image"
    >
      <div class="oak-reference reference-tag">
        <span>{{ product.attributes.field_code }}</span>
      </div>
      <div class="product--content-inner">
        <div class="brand-reference">
          {{ product.brand }}
          <span>{{ product.attributes.field_reference }}</span>
        </div>
        <div
          v-if="product.attributes.field_description"
          class="description"
          v-html="product.attributes.field_description.value"
        />
        <a href="#">Read more</a>
      </div>
    </div>
    <div
      v-else
      class="product-content with-image"
    >
      <div class="image">
        <img :class="withVideoClasses" :src="mainImage">
      </div>
      <div class="product-text">
        <div class="oak-reference reference-tag">
          {{ product.attributes.field_code }}
        </div>
        <div class="product--content-inner">
          <div class="brand-reference">
            {{ product.brand }}
            <span>{{ product.attributes.field_reference }}</span>
          </div>
          <div
            v-if="product.attributes.field_description"
            class="description"
            v-html="product.attributes.field_description.value"
          />
          <a href="#">Read more</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMediaImage } from '../plugins/drupalApi'
import Global from '../plugins/global'

export default {
  name: 'ProductTeaser',
  props: {
    product: {
      type: Object,
      default: null,
      required: true
    },
    isBasic: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    openModalForProduct: function () {
      this.$parent.modalProduct = this.product
      Global.openModal('#product-modal')
    }
  },
  data () {
    return {
      mainImage: null,
      withVideoClasses: '',
      loaded: false
    }
  },
  async created () {
    if (this.product.relationships.field_images.data.length) {
      var image = await getMediaImage(this.product.relationships.field_images.data[0].id)
      this.mainImage = image.included[0].attributes.image_style_uri.oak_product_slider
    } else {
      this.$parent.loadedProducts += 1
    }
    if (this.product.relationships.field_videos.data.length) {
      this.withVideoClasses = 'with-video'
    }
  },
  updated () {
    if (this.mainImage && this.loaded === false) {
      this.loaded = true
      this.$parent.loadedProducts += 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/node/product-teaser";
</style>
