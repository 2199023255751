import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import WaveSurfer from '../libraries/wavesurfer/wavesurfer.js'
import { getAllProducts, getProductsByCategory, getMediaImage, getAllArticles, getTerm } from './drupalApi'

export default {
  mapIncluded: function (includedObj, field) {
    var includedMapped = {}
    for (var key in includedObj) {
      includedMapped[includedObj[key].id] = includedObj[key].attributes[field]
    }
    return includedMapped
  },
  mapIncludedRelations: function (includedObj, field) {
    var includedMapped = {}
    for (var key in includedObj) {
      if (typeof includedObj[key].relationships[field] !== 'undefined') {
        includedMapped[includedObj[key].id] = includedObj[key].relationships[field].data
      }
    }
    return includedMapped
  },
  containsObject: function (obj, list) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true
      }
    }
    return false
  },
  urlSlug: function (str) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
    var to = 'aaaaaeeeeeiiiiooooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  },
  manageHomeHeader: function () {
    var homepageHeader = $('#page-header .homepage')
    if (homepageHeader.length) {
      let changed = false
      let lastScroll = 0
      window.addEventListener('scroll', function () {
        let curScroll = window.pageXOffset || this.document.documentElement.scrollTop
        if (curScroll > lastScroll) {
          if (changed === false) {
            homepageHeader.removeClass('homepage').addClass('allPages')
            homepageHeader.find('.header--logoHome').css('display', 'none')
            homepageHeader.find('.header--logoAll').css('display', 'block')
            changed = true
          }
        } else if (curScroll < 50 && changed === true) {
          homepageHeader.addClass('homepage').removeClass('allPages')
          homepageHeader.find('.header--logoHome').css('display', 'block')
          homepageHeader.find('.header--logoAll').css('display', 'none')
          changed = false
        }
        lastScroll = curScroll <= 0 ? 0 : curScroll
      })
    }
    $('#main-menu-links').css('display', 'none')
  },
  slickCarousel: function (selector, settings, counterSelector) {
    if (typeof counterSelector !== 'undefined' && counterSelector) {
      $(selector).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        var i = 1
        if (currentSlide) {
          i = currentSlide + 1
        }
        $(counterSelector).text(i + '/' + slick.slideCount)
      })
    }
    $(selector).slick(settings)
  },
  initModal: function (selector) {
    $(selector).dialog({
      autoOpen: false,
      modal: true,
      show: true,
      hide: true,
      width: 568
    })
  },
  openModal: function (selector) {
    $(selector).on('dialogopen', function (event, ui) {
      $('body').css({ overflow: 'hidden' })
    })

    $(selector).on('dialogbeforeclose', function (event, ui) {
      $('body').css({ overflow: 'inherit' })
    })

    $(selector).dialog('open')
  },
  toggleElement: function (selector, toHide) {
    if (typeof toHide !== 'undefined' && toHide) {
      $(toHide + ':not(' + selector + ')').hide()
    }
    $(selector).toggle()
  },
  replaceClass: function (selector, oldClass, newClass) {
    $(selector).removeClass(oldClass).addClass(newClass)
  },
  toggleAccordion: function (showcase, slickSettingsPadding) {
    let oParent = $('#showcase--content-' + showcase).parent().parent()
    let oShowcases = $(oParent).find('> .showcase')

    for (let showcaseItem in oShowcases) {
      $(showcaseItem).find('.showcase--header .col-left').removeClass('accordion-opened').addClass('accordion-closed')
    }
    this.toggleElement('#showcase--content-' + showcase, '.showcase--content')
    if ($('#showcase--content-' + showcase, '.showcase--content').is(':visible')) {
      this.replaceClass('#showcase--header-' + showcase + ' .col-left', 'accordion-opened', 'accordion-closed')
    } else {
      this.replaceClass('#showcase--header-' + showcase + ' .col-left', 'accordion-closed', 'accordion-opened')
    }
    this.slickCarousel('.slickPadding', 'destroy')
    this.slickCarousel('.slickPadding', slickSettingsPadding)

    setTimeout(() => {
      $('.slickShowcaseProducts').slick('refresh')
    }, 0)
  },
  waveSurferAudio: function (selector) {
    $(selector).each(function () {
      if ($(this).attr('id')) {
        var wavesurfer = WaveSurfer.create({
          container: '#' + $(this).attr('id'),
          backend: 'MediaElement',
          mediaControls: true,
          mediaType: 'audio'
        })
        wavesurfer.load($(this).data('audio-file'))
      }
    })
  },
  getShowcaseCategory: async function () {
    var categories = {}
    var products = await getAllProducts()

    // uuid / term name mapping
    var termMap = this.mapIncluded(products.included, 'name')
    var termWeightMap = this.mapIncluded(products.included, 'weight')

    // Construct categories with name of terms
    for (var key in products.data) {
      var product = products.data[key]
      var productCats = product.relationships.field_category.data
      var showcaseName = termMap[product.relationships.field_showcase.data.id]
      for (var pKey in productCats) {
        var productCat = productCats[pKey]
        var productCatName = termMap[productCat.id]
        var productCatWeight = termWeightMap[productCat.id]
        if (typeof categories[productCatName] === 'undefined' || typeof categories[productCatName][showcaseName] === 'undefined') {
          if (typeof categories[productCatName] === 'undefined') {
            categories[productCatName] = {
              id: productCat.id,
              name: productCatName,
              weight: productCatWeight,
              showcases: []
            }
          }
          categories[productCatName]['showcases'][showcaseName] = 1
        } else {
          categories[productCatName]['showcases'][showcaseName] = categories[productCatName][showcaseName] + 1
        }
      }
    }

    for (var cKey in categories) {
      var showcases = categories[cKey]['showcases']
      categories[cKey]['showcases'] = []
      var keys = Object.keys(showcases).sort()
      for (var i = 0; i < keys.length; i++) {
        var showcase = keys[i]
        categories[cKey]['showcases'].push({ name: showcase, nb: showcases[showcase] })
      }
    }

    // Order by weight
    var realCategories = []
    for (var oKey in categories) {
      realCategories.push(categories[oKey])
    }

    realCategories.sort(function (a, b) {
      return a.weight > b.weight ? 1 : -1
    })

    return realCategories
  },
  getShowcasesWithCategory: async function () {
    var showcases = {
      nb: 0,
      showcases: []
    }
    var products = await getAllProducts()
    var showcasesArr = []

    // uuid / term name mapping
    var termMap = this.mapIncluded(products.included, 'name')

    // Construct showcases with name of terms
    for (var key in products.data) {
      var product = products.data[key]
      var productCats = product.relationships.field_category.data
      var showcaseName = termMap[product.relationships.field_showcase.data.id]
      for (var pKey in productCats) {
        var productCat = productCats[pKey]
        var productCatName = termMap[productCat.id]
        if (typeof showcasesArr[showcaseName] === 'undefined') {
          var showcaseTerm = await getTerm('showcase', product.relationships.field_showcase.data.id)
          showcasesArr[showcaseName] = {
            id: product.relationships.field_showcase.data.id,
            name: showcaseName,
            media: showcaseTerm.relationships.field_image.data.id,
            categories: []
          }
          showcases.nb = showcases.nb + 1
        }
        if (!this.containsObject({ id: productCat.id }, showcasesArr[showcaseName]['categories'])) {
          showcasesArr[showcaseName]['categories'].push({
            id: productCat.id,
            name: productCatName,
            url: '/category/' + this.urlSlug(productCatName) + '?uuid=' + productCat.id + '&showcase=' + showcaseName
          })
        }
      }
    }

    for (var sKey = 0; sKey < showcasesArr.length; sKey++) {
      var realKey = sKey
      if (realKey < 10) {
        realKey = '0' + realKey
      }
      if (typeof showcasesArr[realKey] !== 'undefined') {
        showcases.showcases.push(showcasesArr[realKey])
      }
    }

    return showcases
  },
  getFullCategory: async function () {
    var categories = {}
    var products = await getAllProducts()

    // uuid / term name mapping
    var termMap = this.mapIncluded(products.included, 'name')
    var termWeightMap = this.mapIncluded(products.included, 'weight')
    var subtitleMap = this.mapIncluded(products.included, 'field_subtitle')
    var imageMap = this.mapIncludedRelations(products.included, 'field_image')
    var secondaryImageMap = this.mapIncludedRelations(products.included, 'field_secondary_image')

    // Construct categories with name of terms
    for (var key in products.data) {
      var product = products.data[key]
      var productCats = product.relationships.field_category.data
      for (var pKey in productCats) {
        var productCat = productCats[pKey]
        if (typeof categories[productCat.id] === 'undefined') {
          var productCatName = termMap[productCat.id]
          var productCatWeight = termWeightMap[productCat.id]
          var productCatSubtitle = subtitleMap[productCat.id]
          var productCatImage = imageMap[productCat.id]
          var productCatSecondaryImage = secondaryImageMap[productCat.id]
          var mainImage = null
          var secondaryImage = null
          if (productCatImage !== null) {
            var image = await getMediaImage(productCatImage.id)
            mainImage = image.included[0].attributes.image_style_uri.oak_category_slider
          }
          if (productCatSecondaryImage !== null) {
            var secImage = await getMediaImage(productCatSecondaryImage.id)
            secondaryImage = secImage.included[0].attributes.image_style_uri.oak_category_slider
          }
          categories[productCat.id] = {
            id: productCat.id,
            name: productCatName,
            weight: productCatWeight,
            subtitle: productCatSubtitle,
            image: mainImage,
            secondaryImage: secondaryImage,
            nb_watches: 0
          }
        }
        categories[productCat.id]['nb_watches'] = categories[productCat.id]['nb_watches'] + 1
      }
    }

    // Order by name
    var realCategories = []
    for (var oKey in categories) {
      realCategories.push(categories[oKey])
    }

    realCategories.sort(function (a, b) {
      return a.weight > b.weight ? 1 : -1
    })

    // Add number
    var number = 1
    for (var nKey in realCategories) {
      realCategories[nKey]['number'] = number
      number = number + 1
    }

    return realCategories
  },
  getCategoryContents: async function (catUuid) {
    var showcases = {}
    var total = 0
    var products = await getProductsByCategory(catUuid)

    // uuid / term name mapping
    var termMap = this.mapIncluded(products.included, 'name')
    var audioMap = this.mapIncludedRelations(products.included, 'field_audio')

    // Construct product array grouped by showcases
    for (var key in products.data) {
      var product = products.data[key]
      product.brand = termMap[product.relationships.field_brand.data.id]
      var showcaseName = termMap[product.relationships.field_showcase.data.id]
      if (typeof showcases[showcaseName] === 'undefined') {
        showcases[showcaseName] = { name: showcaseName, audios: audioMap[product.relationships.field_showcase.data.id], illustrated: [], basic: [] }
      }
      if (product.relationships.field_images.data.length === 0 && product.relationships.field_videos.data.length === 0) {
        showcases[showcaseName].basic.push(product)
      } else {
        showcases[showcaseName].illustrated.push(product)
      }
      total = total + 1
    }

    // Order by field_code
    for (var oKey in showcases) {
      showcases[oKey].illustrated.sort(function (a, b) {
        return a.attributes.field_code.localeCompare(b.attributes.field_code)
      })
      showcases[oKey].basic.sort(function (a, b) {
        return a.attributes.field_code.localeCompare(b.attributes.field_code)
      })
    }

    // Order by name
    var realShowCases = []
    for (var sKey in showcases) {
      realShowCases.push(showcases[sKey])
    }

    realShowCases.sort(function (a, b) {
      return a.name.localeCompare(b.name)
    })

    return { 'total': total, 'showcases': realShowCases }
  },
  getAllArticles: async function () {
    var datas = await getAllArticles()
    var articles = datas.data

    // uuid / term name mapping
    var termMap = this.mapIncluded(datas.included, 'name')

    // Construct product array grouped by showcases
    for (var key in articles) {
      var article = articles[key]
      article.included = []
      for (var tagKey in article.relationships.field_tags.data) {
        var tagId = article.relationships.field_tags.data[tagKey].id
        var include = {
          id: tagId,
          attributes: {
            name: termMap[tagId]
          }
        }
        article.included.push(include)
      }
      articles[key] = article
    }

    return articles
  }
}
