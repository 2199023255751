<template>
  <div class="page-exhibition-map page">
    <Header
      header-classes="allPages clearfix"
    />
    <div class="exhibition-map--inner padding-content">
      <div class="map-illustration">
        <h1>Exhibition&nbsp;Map</h1>
        <img src="@/assets/content/map.png" alt="OAK collection - Exposition map" />
      </div>
      <div
        v-for="category in categories"
        :key="category.name"
        class="product--categories"
      >
        <div class="product--categorie">
          <div class="category">
            {{ category.name }}
          </div>
          <div class="showcases">
          <span
            v-for="showcase in category.showcases"
            :key="showcase.name"
            class="showcase"
          >
            <router-link
              :to="{ name: 'Category', params: { url: urlSlug(category.name) }, query: { uuid: category.id, showcase: showcase.name } }"
            >
              {{ showcase.name }}
            </router-link>
          </span>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Global from '../plugins/global'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'ExhibitionMap',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      categories: []
    }
  },
  async created () {
    this.categories = await Global.getShowcaseCategory()
  }
}
</script>

<style lang="scss">
@import "./src/scss/styles";
@import "./src/scss/components/node/exhibition-map";
</style>
