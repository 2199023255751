import axios from 'axios'

const DrupalBaseUrl = process.env.VUE_APP_BASE_URL + '' + process.env.VUE_APP_API_PATH

async function axiosCall (url) {
  const response = await axios.get(url)
  return response.data
}

async function axiosCallIterate (url) {
  let result = {}
  let response = await axios.get(url)
  result.data = response.data.data
  result.included = response.data.included
  while (typeof response.data.links.next !== 'undefined') {
    response = await axios.get(response.data.links.next.href.replace('http://', 'https://'))
    result.data = [...result.data, ...response.data.data]
    result.included = [...result.included, ...response.data.included]
  }
  return result
}

async function getAllArticles () {
  const response = await axiosCall(DrupalBaseUrl + 'node/article?include=field_tags&sort[sort-created][path]=created&sort[sort-created][direction]=DESC')
  return response
}

async function getArticle (uuid) {
  const response = await axiosCall(DrupalBaseUrl + 'node/article/' + uuid)
  return response.data
}

async function getLastArticle () {
  const response = await axiosCall(DrupalBaseUrl + 'node/article?include=field_tags&filter[image][condition][operator]=IS%20NOT%20NULL&filter[image][condition][path]=field_teaser_media.id&sort[sort-created][path]=created&sort[sort-created][direction]=DESC&page[limit]=1')
  if (response.data.length) {
    response.data[0].included = response.included
    return response.data[0]
  }

  return null
}

async function getParagraph (type, uuid) {
  const response = await axiosCall(DrupalBaseUrl + 'paragraph/' + type + '/' + uuid)
  return response.data
}

async function getMedia (type, uuid, fieldName) {
  const response = await axiosCall(DrupalBaseUrl + 'media/' + type + '/' + uuid + '?include=' + fieldName + '&fields[file--file]=uri,url,image_style_uri')
  response.data.included = response.included
  return response.data
}

async function getMediaImage (uuid) {
  const response = await getMedia('image', uuid, 'field_image')
  return response
}

async function getMediaVideo (uuid) {
  const response = await axiosCall(DrupalBaseUrl + 'media/video/' + uuid)
  return response.data
}

async function getGalleryImages (uuid) {
  const response = await getMedia('gallery', uuid, 'field_media_images')
  return response
}

async function getMediaAudio (uuid) {
  const response = await getMedia('audio', uuid, 'field_media_audio_file')
  return response
}

async function getEntityParagraphs (url) {
  const response = await axiosCall(url)
  return response.data
}

async function getAllPages () {
  const response = await axiosCall(DrupalBaseUrl + 'node/page')
  return response.data
}

async function getPage (uuid) {
  const response = await axiosCall(DrupalBaseUrl + 'node/page/' + uuid)
  return response.data
}

async function getAllProducts () {
  const response = await axiosCallIterate(DrupalBaseUrl + 'node/product?include=field_category,field_showcase')
  return response
}

async function getProductsByCategory (catUuid) {
  const response = await axiosCall(DrupalBaseUrl + 'node/product?filter[field_category.id][value]=' + catUuid + '&include=field_brand,field_showcase')
  return response
}

async function getProduct (uuid) {
  const response = await axiosCall(DrupalBaseUrl + 'node/product/' + uuid)
  return response.data
}

async function getTermList (vocabulary) {
  const response = await axiosCall(DrupalBaseUrl + 'taxonomy_term/' + vocabulary)
  return response.data
}

async function getOtherTermList (vocabulary, exception) {
  const response = await axiosCall(DrupalBaseUrl + 'taxonomy_term/' + vocabulary + '?filter[id][value]=' + exception + '&filter[id][operator]=<>')
  return response.data
}

async function getTerm (vocabulary, uuid, complement) {
  var url = DrupalBaseUrl + 'taxonomy_term/' + vocabulary + '/' + uuid
  if (typeof complement !== 'undefined') {
    url = url + '?' + complement
  }

  const response = await axiosCall(url)
  if (typeof complement !== 'undefined') {
    response.data.included = response.included
  }
  return response.data
}

export { DrupalBaseUrl,
  getAllArticles,
  getArticle,
  getLastArticle,
  getParagraph,
  getMediaImage,
  getMediaVideo,
  getGalleryImages,
  getMediaAudio,
  getEntityParagraphs,
  getAllPages,
  getPage,
  getAllProducts,
  getProductsByCategory,
  getProduct,
  getTermList,
  getOtherTermList,
  getTerm
}
