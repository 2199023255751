<template>
  <div class="paragraph paragraph--video">
    <div
      v-if="videoUrl"
      class="field--video"
    >
      <iframe :src="videoUrl" frameborder="0" allowtransparency class="media-oembed-content"></iframe>
    </div>
  </div>
</template>

<script>
import { getParagraph, getMediaVideo } from '../plugins/drupalApi'

export default {
  name: 'ParagraphVideo',
  props: {
    uuid: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      ParagraphVideo: [],
      videoUrl: null
    }
  },
  async created () {
    this.ParagraphVideo = await getParagraph('video', this.uuid)
    this.ParagraphVideo.video = await getMediaVideo(this.ParagraphVideo.relationships.field_video.data.id)
    this.videoUrl = process.env.VUE_APP_BASE_URL + 'media/simple-embed?url=' + this.ParagraphVideo.video.attributes.field_media_video_embed_field
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/paragraphs/paragraph-video"
</style>
