<template>
  <div class="page-homepage page">
    <Header
      header-classes="homepage clearfix"
      :is-home="true"
    />

    <div class="title-wrapper padding-content">
      <div class="subtitle">Welcome to</div>
      <h1>The Oak Collection</h1>
      <div class="chapo">A one of a kind exhibition<br>of extraodinary watches</div>
      <div class="dates">19 - 25 May, 2022</div>
    </div>

    <div class='links padding-content'>
      <div class="btn-container">
        <a href="/category/tank?uuid=a44e5009-76c8-4cb6-9ff6-8e469e3aa35e&showcase=01" class="btn btn-primary">Let my journey begin</a>
      </div>
      <div class="btn-cols">
        <div class="btn-col col-left">
          <a href="#"><img src="@/assets/layout/logo_museum.png" alt="the design museum"/></a>
        </div>
      </div>
    </div>

    <div class="text-block padding-content">
      <p class="align-left">Four decades of watch collecting passion</p>
      <p class="align-right">Revealed<br>to the world</p>
    </div>

    <div class="categories">
      <h2 class="section--title padding-content">View by categories</h2>
      <div
        v-if="Object.keys(categories).length"
        class="category-inner slick"
      >
        <div
          v-for="categorie in categories"
          :key="categorie.id"
          class="category-slide"
        >
          <router-link
            :to="{ name: 'Category', params: { url: urlSlug(categorie.name) }, query: { uuid: categorie.id } }"
            class=""
          >
            <CategorySlide
              :category="categorie"
              :total="Object.keys(categories).length"
            />
          </router-link>
        </div>
      </div>
    </div>

    <div class='exhibition-map padding-content'>
      <div class="exhibition-map--container">
        <div class="exhibition-map--content">
          <p class="text">Looking for a section?</p>
          <a href="/exhibition-map" class="btn btn-secondary">Exhibition map</a>
        </div>
      </div>
    </div>

    <div class="exclusive-content padding-content">
      <div
        v-if="article"
        class="articles"
      >
        <h2 class="section--title">Discover oak exclusive content</h2>
        <router-link
          v-if="article.attributes"
          :to="{ name: 'Article', params: { url: urlSlug(article.attributes.title) }, query: { uuid: article.id } }"
        >
          <ArticleTeaser
            :article="article"
          />
        </router-link>
      </div>
      <div class="link">
        <router-link
          v-if="exclusiveContent && exclusiveContent.attributes"
          :to="{ name: 'Article', params: { url: urlSlug(exclusiveContent.attributes.title) }, query: { uuid: exclusiveContent.id } }"
        >
          All exclusive content
        </router-link>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { getLastArticle } from '../plugins/drupalApi'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CategorySlide from '../components/CategorySlide.vue'
import ArticleTeaser from '../components/ArticleTeaser.vue'
import '../libraries/slick/slick.min.js'
import Global from '../plugins/global'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    CategorySlide,
    ArticleTeaser
  },
  data () {
    return {
      article: null,
      categories: [],
      exclusiveContent: null,
      slickSettings: {
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerPadding: '40px'
      }
    }
  },
  async created () {
    this.article = await getLastArticle()
    this.categories = await Global.getFullCategory()

    const articles = await Global.getAllArticles()

    if (articles[0]) {
      this.exclusiveContent = articles[0]
    }
  },
  updated () {
    Global.slickCarousel('.slick:not(.slick-initialized)', this.slickSettings)
  }
}
</script>

<style lang="scss">
@import "./src/scss/styles";
@import "./src/scss/components/node/home";
@import "./src/libraries/slick/slick.scss";
@import "./src/libraries/slick/slick-theme.scss";
</style>
