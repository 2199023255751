<template>
  <div class="page-article--detail page">
    <Header
      header-classes="allPages clearfix"
    />
    <div class="padding-content">
      <h1 v-if="article.attributes">
        {{ article.attributes.title }}
      </h1>
    </div>
    <div v-if="article.relationships">
      <div
        v-for="relation in article.relationships.field_paragraphs.data"
        :key="relation.id"
      >
        <div v-if="relation.type === 'paragraph--text'">
          <ParagraphText
            :uuid="relation.id"
          />
        </div>
        <div v-if="relation.type === 'paragraph--image'">
          <ParagraphImage
            :uuid="relation.id"
          />
        </div>
        <div v-if="relation.type === 'paragraph--gallery'">
          <ParagraphGallery
            :uuid="relation.id"
          />
        </div>
        <div v-if="relation.type === 'paragraph--quote'">
          <ParagraphQuote
            :uuid="relation.id"
          />
        </div>
        <div v-if="relation.type === 'paragraph--video'">
          <ParagraphVideo
            :uuid="relation.id"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { getArticle } from '../plugins/drupalApi'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import ParagraphText from '../components/ParagraphText.vue'
import ParagraphImage from '../components/ParagraphImage.vue'
import ParagraphGallery from '../components/ParagraphGallery.vue'
import ParagraphQuote from '../components/ParagraphQuote.vue'
import ParagraphVideo from '../components/ParagraphVideo.vue'

export default {
  name: 'Article',
  components: {
    Header,
    Footer,
    ParagraphText,
    ParagraphImage,
    ParagraphGallery,
    ParagraphQuote,
    ParagraphVideo
  },
  data () {
    return {
      article: []
    }
  },
  async created () {
    this.article = await getArticle(this.$route.query.uuid)
  }
}
</script>

<style lang="scss">
@import "./src/scss/styles";
</style>
