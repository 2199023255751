<template>
  <div class="product--modal">
    <div class="product-content">
      <div class="oak-reference reference-tag">
        {{ product.attributes.field_code }}
      </div>
      <div class="product--content-inner">
        <div class="brand">
          {{ product.brand }}
        </div>
        <div class="reference">
          {{ product.attributes.field_reference }}
        </div>
      </div>

      <div class="images">
        <div
          v-if="images.length"
          class="images-slider slick"
        >
          <div
            v-for="image in images"
            :key="image.id"
            class="slide"
          >
            <img :src="image.url" />
          </div>
        </div>
      </div>

      <div class="description--wrapper">
        <h2 class="section--title padding-content">
          About this timepiece
        </h2>
        <div
          v-if="product.attributes.field_description"
          class="description"
          v-html="product.attributes.field_description.value"
        />
      </div>

      <div
        v-if="videos.length"
        class="videos"
      >
        <h2 class="section--title padding-content">
          videos
        </h2>
        <div
          v-for="video in videos"
          :key="video.id"
          class="video"
        >
          <iframe :src="video.url" frameborder="0" allowtransparency class="media-oembed-content"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMediaImage, getMediaVideo } from '../plugins/drupalApi'
import Global from '../plugins/global'

export default {
  name: 'ProductModal',
  props: {
    product: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      images: [],
      videos: [],
      oldProduct: true
    }
  },
  async created () {
    var imageArr = []
    var videoArr = []
    this.oldProduct = this.product.id
    if (this.product.relationships.field_images.data.length) {
      for (var imageKey in this.product.relationships.field_images.data) {
        var image = await getMediaImage(this.product.relationships.field_images.data[imageKey].id)
        imageArr.push({
          url: image.included[0].attributes.image_style_uri.oak_product_slider,
          id: image.id
        })
      }
      this.images = imageArr
    }
    if (this.product.relationships.field_videos.data.length) {
      for (var videoKey in this.product.relationships.field_videos.data) {
        var video = await getMediaVideo(this.product.relationships.field_videos.data[videoKey].id)
        videoArr.push({
          url: process.env.VUE_APP_BASE_URL + 'media/simple-embed?url=' + video.attributes.field_media_video_embed_field,
          id: video.id
        })
      }
      this.videos = videoArr
    }
  },
  async updated () {
    if (this.oldProduct !== this.product.id) {
      this.images = []
      this.videos = []
      var imageArr = []
      var videoArr = []
      this.oldProduct = this.product.id
      if (this.product.relationships.field_images.data.length) {
        for (var imageKey in this.product.relationships.field_images.data) {
          var image = await getMediaImage(this.product.relationships.field_images.data[imageKey].id)
          imageArr.push({
            url: image.included[0].attributes.image_style_uri.oak_product_main,
            id: image.id
          })
        }
        this.images = imageArr
      }
      if (this.product.relationships.field_videos.data.length) {
        for (var videoKey in this.product.relationships.field_videos.data) {
          var video = await getMediaVideo(this.product.relationships.field_videos.data[videoKey].id)
          videoArr.push({
            url: process.env.VUE_APP_BASE_URL + 'media/simple-embed?url=' + video.attributes.field_media_video_embed_field,
            id: video.id
          })
        }
        this.videos = videoArr
      }
    }
    Global.slickCarousel('.slick:not(.slick-initialized)', this.slickSettings)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/node/product-modal";
</style>
