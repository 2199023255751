// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import Vue from 'vue'
import Home from '@/pages/Home.vue'
import Articles from '@/pages/Articles.vue'
import Article from '@/pages/Article.vue'
import Page from '@/pages/Page.vue'
import Desktop from '@/pages/Desktop.vue'
import ExhibitionMap from '@/pages/ExhibitionMap.vue'
import Category from '@/pages/Category.vue'

// Sample Authorization Guard
// function checkAuth(to, from, next) {
//   if (IsAuthenticated()) next();
//   else next("/login");
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/desktop',
    name: 'Desktop',
    component: Desktop
  },
  {
    path: '/exhibition-map',
    name: 'ExhibitionMap',
    component: ExhibitionMap,
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/category/:url',
    name: 'Category',
    component: Category,
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/article/:url',
    name: 'Article',
    component: Article,
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/page/:url',
    name: 'Page',
    component: Page,
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'abstract'
})

const DEFAULT_TITLE = 'OAK Collection'
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.name || DEFAULT_TITLE
  })
})

export default router
