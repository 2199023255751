<template>
  <div class="paragraph paragraph--image">
    <div
      v-if="imageUrl"
      class="field--image"
    >
      <img :src="imageUrl">
    </div>
  </div>
</template>

<script>
import { getParagraph, getMediaImage } from '../plugins/drupalApi'

export default {
  name: 'ParagraphImage',
  props: {
    uuid: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      paragraphImage: [],
      imageUrl: null
    }
  },
  async created () {
    this.paragraphImage = await getParagraph('image', this.uuid)
    this.paragraphImage.image = await getMediaImage(this.paragraphImage.relationships.field_image.data.id)
    this.imageUrl = this.paragraphImage.image.included[0].attributes.image_style_uri.oak_article_large
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/paragraphs/paragraph-image"
</style>
