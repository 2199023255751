<template>
  <div class="page-category page">
    <Header
      header-classes="allPages clearfix"
    />

    <div
      v-if="category.attributes"
      class="category--header"
    >
      <div class="col-left">
        <img :src="imagesCatUrl">
      </div>
      <div class="col-right">
        <h2 class="category--subtitle">
          {{ category.attributes.field_subtitle }}
        </h2>
        <h1>
          {{ category.attributes.name }}
        </h1>
        <div
          v-if="products"
          class="category-watches--number"
        >
          {{ products.total }} <span v-if="products.total > 1">watches</span> <span v-if="products.total == 1">watch</span>
        </div>
        <div v-if="showcasesAudio.length" class="btn btn-primary">
          Audio guide
        </div>
        <div
          v-if="showcasesAudio.length"
          class="audios audioSlick"
        >
          <div
            v-for="audioFile in showcasesAudio"
            :key="audioFile.id"
          >
            <div
            class="wavesurfer"
            :id="audioFile.id"
            :data-audio-file="audioFile.file"
            :data-showcase='audioFile.showcase' />
            <p class="showcaseNumber">Showcase {{ audioFile.showcase }}</p>
          </div>
        </div>
        <div v-if="showcasesAudio.length" class="slide-controls">
          <a role="button" class="slide-arrow arrow-prev" title="Previous slide">&lt;</a>
          <span id="audio-indicator" class="pager-indicator"></span>
          <a role="button" class="slide-arrow arrow-next" title="Next slide">&gt;</a>
        </div>
      </div>
    </div>

    <div
      v-if="otherCat"
      class="category--other"
    >
      <p class="section--title padding-content">
        Choose another showcase
      </p>
      <div
        v-if="otherCat.showcases.length"
        class="section--content slickOtherCat"
      >
        <div
          v-for="curOtherShowcase in otherCat.showcases"
          :key="curOtherShowcase.id"
          class="product-other-cat"
        >
          <ShowcaseTeaser
            :showcase="curOtherShowcase"
          />
        </div>
      </div>
      <div class="slide-controls">
        <a role="button" class="slide-arrow arrow-prev" title="Previous slide">&lt;</a>
        <span id="showcase-indicator" class="pager-indicator"></span>
        <a role="button" class="slide-arrow arrow-next" title="Next slide">&gt;</a>
      </div>
    </div>

    <div
      v-if="category.attributes"
      class="category--introduction padding-content"
    >
      <h2 v-if="category.attributes.description" class="section--title">
        Introduction
      </h2>
      <div class="section--content">
        <div
          v-if="category.attributes.description"
          class="category--description"
          v-html=" category.attributes.description.value"
        />
      </div>
    </div>

    <div
      v-if="products && category.attributes"
      class="category--showcases"
    >
      <h2 class="section--title padding-content">
        Explore {{ category.attributes.name }} collection's highlight
      </h2>
      <div class="section--content">
        <div
          v-for="productList in products.showcases"
          :key="productList.name"
          class="showcase"
        >
          <div
            @click="toggleAccordion(productList.name)"
            :id="'showcase--header-'+productList.name"
            class="showcase--header padding-content"
          >
            <div class="col-left accordion-closed">Showcase {{ productList.name }}</div>
            <div class="col-right">{{ productList.basic.length + productList.illustrated.length }} <span v-if="productList.basic.length + productList.illustrated.length > 1"> watches</span> <span v-if="productList.basic.length + productList.illustrated.length == 1"> watch</span> </div>
          </div>
          <div
            :id="'showcase--content-'+productList.name"
            class="showcase--content"
          >
            <div
              v-if="productList.illustrated.length"
              class="showcase--products--illustrated slickShowcaseProducts"
            >
              <div
                v-for="product in productList.illustrated"
                :key="product.id"
                class="product--illustrated--teaser"
              >
                <ProductTeaser
                  :product="product"
                />
              </div>
            </div>
            <div class="showcase--products--basic-wrapper padding-content">
              <div
                v-for="product in productList.basic"
                :key="product.id"
                class="showcase--products--basic"
              >
                <ProductTeaser
                  :product="product"
                  :is-basic="true"
                />
              </div>
            </div>
          </div>
          <div class="padding-content">
            <div class="border">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

    <div id="product-modal">
      <div
        v-if="modalProduct.attributes"
        class="product-modal--wrapper"
      >
        <ProductModal
          :product="modalProduct"
        />
      </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
import { getTerm, getMediaImage, getMediaAudio } from '../plugins/drupalApi'
import '../libraries/slick/slick.min.js'
import Global from '../plugins/global'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import ProductTeaser from '../components/ProductTeaser.vue'
import ProductModal from '../components/ProductModal.vue'
import ShowcaseTeaser from '../components/ShowcaseTeaser.vue'

export default {
  name: 'Category',
  components: {
    Header,
    Footer,
    ProductTeaser,
    ProductModal,
    ShowcaseTeaser
  },
  data () {
    return {
      category: {},
      otherCat: null,
      loadedCats: 0,
      imagesCatUrl: '',
      secondaryImagesCatUrl: '',
      showcasesAudio: [],
      products: {},
      loadedProducts: 0,
      modalProduct: {},
      slickSettingsProducts: {
        dots: false,
        arrows: false,
        infinite: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerPadding: '40px'
      },
      toggledAccordion: null
    }
  },
  methods: {
    toggleAccordion: function (showcase) {
      this.toggledAccordion = showcase

      Global.toggleAccordion(showcase, this.slickSettingsProducts)
    }
  },
  async created () {
    this.category = await getTerm('category', this.$route.query.uuid)
    this.otherCat = await Global.getShowcasesWithCategory()
    var image = await getMediaImage(this.category.relationships.field_image.data.id)
    this.imagesCatUrl = image.included[0].attributes.image_style_uri.oak_category_main
    var secondaryImage = await getMediaImage(this.category.relationships.field_secondary_image.data.id)
    this.secondaryImagesCatUrl = secondaryImage.included[0].attributes.image_style_uri.oak_category_main
    this.products = await Global.getCategoryContents(this.$route.query.uuid)
    var audioArr = []
    for (var productKey in this.products.showcases) {
      if (this.products.showcases[productKey].audios.length) {
        for (var audioKey in this.products.showcases[productKey].audios) {
          var audio = await getMediaAudio(this.products.showcases[productKey].audios[audioKey].id)
          audioArr.push({
            id: 'showcase-audio-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 6),
            file: process.env.VUE_APP_BASE_URL + audio.included[0].attributes.uri.url,
            showcase: this.products.showcases[productKey].name
          })
        }
      }
    }
    this.showcasesAudio = audioArr
    Global.initModal('#product-modal')
  },
  updated () {
    if ($('.wavesurfer:not(.wave-initialized)').length) {
      Global.waveSurferAudio('.wavesurfer')
      $('.wavesurfer:not(.wave-initialized)').addClass('wave-initialized')
      var audioSlickSettings = {
        dots: false,
        arrows: true,
        appendArrows: $('.category--header .slide-controls'),
        prevArrow: $('.category--header .slide-controls .arrow-prev'),
        nextArrow: $('.category--header .slide-controls .arrow-next'),
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerPadding: '0px'
      }
      Global.slickCarousel('.audioSlick:not(.slick-initialized)', audioSlickSettings, '#audio-indicator')

      const url = new URL(window.location.href)
      const showcaseId = url.searchParams.get('showcase')

      if (showcaseId) {
        let showcaseIndex = 0

        for (let index in this.showcasesAudio) {
          if (this.showcasesAudio[index].showcase === showcaseId) {
            showcaseIndex = index

            break
          }
        }

        $('.audioSlick.slick-initialized').slick('getSlick').slickGoTo(showcaseIndex)
      }
    }
    Global.slickCarousel('.slickShowcaseProducts:not(.slick-initialized)', this.slickSettingsProducts)
    if (this.otherCat && this.loadedCats > 0 && this.otherCat.nb === this.loadedCats) {
      var slickOtherCatOptions = {
        dots: false,
        arrows: true,
        appendArrows: $('.category--other .slide-controls'),
        prevArrow: $('.category--other .slide-controls .arrow-prev'),
        nextArrow: $('.category--other .slide-controls .arrow-next'),
        infinite: true,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        centerPadding: '0px'
      }
      Global.slickCarousel('.slickOtherCat:not(.slick-initialized)', slickOtherCatOptions, '#showcase-indicator')
    }

    if (this.products.showcases && this.loadedProducts > 0 && this.products.total === this.loadedProducts) {
      setTimeout(() => {
        const visibleShowcases = $('.showcase--content:visible')

        if (this.toggledAccordion === null && this.$route.query.showcase === undefined) {
          $('.showcase--content').hide()
        } else if (visibleShowcases.length === 0) {
          let showcase

          if (this.$route.query.showcase) {
            showcase = this.$route.query.showcase
          } else {
            var slick = $('.audios.slick-initialized').slick('getSlick')
            var currentSlideDom = slick.$slides.get(0)

            showcase = $(currentSlideDom).data('showcase')
          }

          this.toggleAccordion(showcase)
        }
      }, 500)
    }
    // Open showcase on slick slide events
    $('.audios').on('afterChange', function (event, slick, currentSlide, nextSlide) {
      var currentSlideDom = slick.$slides.get(currentSlide)

      const previousSlideDom = slick.$slides.get(currentSlide - 1)
      const nextSlideDom = (currentSlide === slick.$slides.length - 1) ? slick.$slides.get(0) : slick.$slides.get(currentSlide + 1)

      if (previousSlideDom) {
        const audioPlayer = previousSlideDom.querySelector('audio')

        if (audioPlayer) {
          audioPlayer.pause()
          audioPlayer.currentTime = 0
        }
      }

      if (nextSlideDom) {
        const audioPlayer2 = nextSlideDom.querySelector('audio')

        if (audioPlayer2) {
          audioPlayer2.pause()
          audioPlayer2.currentTime = 0
        }
      }

      var currentShowCase = $(currentSlideDom).find('> div').attr('data-showcase')
      Global.toggleAccordion(currentShowCase, this.slickSettingsProducts)
    })
  }
}
</script>

<style lang="scss">
@import "./src/scss/styles";
@import "./src/libraries/slick/slick.scss";
@import "./src/libraries/slick/slick-theme.scss";
@import "./src/scss/components/taxonomy/category";
@import "./src/scss/components/modal";
</style>
<style lang="css">
@import "../../node_modules/jquery-ui-dist/jquery-ui.css";
@import "../../node_modules/jquery-ui-dist/jquery-ui.theme.css";
</style>
