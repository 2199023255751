<template>
  <div class="page-article--list page">
    <Header
      header-classes="allPages clearfix"
    />

    <div class="padding-content">
      <div class="title">
        <div class="subtitle">Discover</div>
        <h1>Exclusive content</h1>
      </div>

      <div class="articles">
        <div
          v-for="article in articles"
          :key="article.id"
          class="article--teaser"
        >
          <router-link
            :to="{ name: 'Article', params: { url: urlSlug(article.attributes.title) }, query: { uuid: article.id } }"
          >
            <ArticleTeaser
              :article="article"
            />
          </router-link>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Global from '../plugins/global'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import ArticleTeaser from '../components/ArticleTeaser.vue'

export default {
  name: 'ArticleList',
  components: {
    Header,
    Footer,
    ArticleTeaser
  },
  data () {
    return {
      articles: []
    }
  },
  async created () {
    this.articles = await Global.getAllArticles()
  }
}
</script>

<style lang="scss">
@import "./src/scss/styles";
</style>
