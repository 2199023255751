<template>
  <div class="category-slide--inner">
    <div class="image">
      <img :src="category.secondaryImage">
    </div>
    <div class="category-slide--content">
      <div class="col-left">
        <div class="position">
          <span class="count">{{ category.number }}</span>
          <span class="total">/ {{ total }}</span>
        </div>
      </div>
      <div class="col-right">
        <div class="category-name">
          {{ category.name }}
        </div>
        <div class="category-nb-watches">
          {{ category.nb_watches }} <span v-if="category.nb_watches > 1">watches</span> <span v-if="category.nb_watches == 1">watch</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategorySlide',
  props: {
    category: {
      type: Object,
      default: null,
      required: true
    },
    total: {
      type: Number,
      default: 0,
      required: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/taxonomy/category-slide";
</style>
