<template>
  <footer id="page-footer">
    <div class="instagram-block">
      <h2 class="section--title">Instagram</h2>
      <div class="section--subtitle"><p>Share your pictures with the hashtag</p></div>
      <div class="section--hashtag">#oakcollection</div>
      <a href="https://www.instagram.com/the.oak.collection" target="_blank" class="btn btn-secondary instagram"><!-- <icon icon="camera" :size="5" color="#ffffff" /> --> Follow us</a>
    </div>

    <div class="footer-menu">
      <div class="col-left">
        <ul class="menu-links">
          <li><a href="/page/privacy?uuid=5bd70a6e-bcef-4eda-b429-e8025e63ab9b">Privacy</a></li>
          <li><a href="/page/term-of-use?uuid=323d0922-1bc1-42d8-be84-4489c0cf48f7">Term and conditions</a></li>
          <li><a href="/page/credits?uuid=74a51da2-9c8a-44d3-a4d3-de416a06ba73">Credits</a></li>
        </ul>
      </div>
      <div class="col-middle">
          <a href="https://designmuseum.org"><img src="@/assets/layout/logo_museum.png" alt="the design museum"/></a>
      </div>
      <div class="col-right">
          <a href="/"><img src="@/assets/layout/logo.png" alt="the design museum"/></a>
      </div>
    </div>
  </footer>
</template>

<script>
// import Icon from './Icon.vue'

export default {
  name: 'Footer',
  components: {
    // Icon
  }
}
</script>
