<template>
  <div id="app">

    <Header
      header-classes="allPages clearfix"
    />

    <h1>Home page</h1>
    <HelloWorld msg="Desktop page" />

    <Footer />
  </div>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Desktop',
  components: {
    HelloWorld,
    Header,
    Footer
  }
}
</script>

<style lang="scss">
@import "./src/scss/styles";
</style>
