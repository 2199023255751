import { createApp } from 'vue'
import DrupalAPI from './plugins/drupalApi'
import router from './router'
import App from './App.vue'

document.title = 'OAK Collection'
let app = createApp(App)

app.mixin({
  methods: {
    urlSlug: function (str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
      var to = 'aaaaaeeeeeiiiiooooouuuunc------'
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    }
  }
})

app.use(router)
app.use(DrupalAPI)
app.mount('#app')
