<template>
  <div class="paragraph paragraph--quote">
    <div class="paragraph--quote-inner">
      <div
        v-if="paragraphQuote.attributes"
        class="field--text"
        v-html=" paragraphQuote.attributes.field_text.value"
      />
    </div>
  </div>
</template>

<script>
import { getParagraph } from '../plugins/drupalApi'

export default {
  name: 'ParagraphQuote',
  props: {
    uuid: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      paragraphQuote: []
    }
  },
  async created () {
    this.paragraphQuote = await getParagraph('quote', this.uuid)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/scss/components/paragraphs/paragraph-quote"
</style>
